import React, { Component } from "react";
import { Redirect, HashRouter, Route, Switch } from "react-router-dom";
import "./Style.scss";
//import { getPermissoes } from "./pages/Login/Permissoes"

const loading = () => <div className="animated fadeIn pt-3 text-center"></div>;

const AppLayout = React.lazy(() => import("./layout/AppLayout"));
const Login = React.lazy(() => import("./pages/Login/Login"));
const AlterarSenha = React.lazy(() => import("./pages/Login/AlterarSenha"));

const isAuthenticated = () =>
  localStorage.getItem("token") !== null

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loadPermissoes: false,
    };
  }

  UNSAFE_componentWillMount() {
    this.loadPermissoes();
  }

  loadPermissoes = async () => {
    if (
      localStorage.getItem("username") !== null &&
      localStorage.getItem("username") !== undefined
    ) {
      //await getPermissoes(localStorage.getItem("username"))
      //this.setState({ loadPermissoes: true })
    }
  };
  render() {
    setInterval(() => {
      const elements = document.getElementsByClassName("apexcharts-menu-icon");

      [...elements].forEach((element) =>
        element.addEventListener("mouseover", () => element.setAttribute("title", "Imagem"))
      );
    }, 1000);

    return (
      <HashRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />

            <Route
              path="/primeiroLogin"
              name="interativit"
              render={(props) =>
                isAuthenticated() ? (
                  <AlterarSenha {...{ ...props, firstLogin: true }} />
                ) : (
                  <Redirect to="/login"></Redirect>
                )
              }
            />

            <Route
              render={(props) =>
                isAuthenticated() ? (
                  <Route path="/" name="Home" render={(props) => <AppLayout {...props} />} />
                ) : (
                  <Redirect to="/login"></Redirect>
                )
              }
            />
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
